import { useState, cloneElement } from 'react';
import { Box, Button, Modal, Tooltip } from '@mui/material';
import { style } from './style';

export const UI = ({
  btnTitle,
  children,
  color,
  background,
  advert,
  tooltip,
  tooltipTitle,
  tender,
  company,
  publish,
  size,
}: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box>
      {tooltip ? (
        <Tooltip title={tooltipTitle} arrow>
          <Button
            sx={{
              width: '100%',
              minWidth: '20px',
              color: color,
              background: background,
            }}
            size="small"
            color="error"
            variant="contained"
            onClick={handleOpen}
          >
            {btnTitle}
          </Button>
        </Tooltip>
      ) : (
        <Button
          sx={{
            width: '100%',
            minWidth: '20px',
            color: color,
            background: background,
          }}
          size="small"
          color="error"
          variant="contained"
          onClick={handleOpen}
        >
          {btnTitle}
        </Button>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {cloneElement(children, {
            advert,
            tender,
            company,
            publish,
            setOpen,
          })}
        </Box>
      </Modal>
    </Box>
  );
};
